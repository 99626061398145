@import 'styles/redesign-variables'

.galleryImage
  min-width: 16rem
  min-height: 11rem
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  position: relative
  background-color: $beige-600
  background-position: center center
  background-size: contain
  background-repeat: no-repeat
  box-sizing: border-box
  color: $black
  border-radius: .6rem
  margin: .2rem
  aspect-ratio: 3/2
  outline: solid .25rem transparent

  &.error
    border: 1px solid $coral-200
    background: $coral-100

  &.selected
    outline: solid .25rem $blue-400

  &.clickable
    cursor: pointer

  &.hoverSelectButton

    .selectButton
      visibility: hidden

    .selected
      visibility: visible

    &:hover
      .selectButton
        visibility: visible

  &.hoverFavoriteButton

    .favButton
      visibility: hidden

    .favSelected
      visibility: visible

    .favDisabled
      cursor: wait

    &:hover
      .favButton
        visibility: visible

  &.hoverEditButton

    .editButton
      visibility: hidden

    &:hover
      .editButton
        visibility: visible

  &.hoverEditVisualButton
    .editVisualButton
      visibility: hidden

    &:hover
      .editVisualButton
        visibility: visible

  &.hoverDownloadableButton
    .downloadButton
      visibility: hidden

    &:hover
      .downloadButton
        visibility: visible

  .errorMsg
    line-height: 2rem

  .iconButton
    display: flex
    justify-content: center
    align-items: center
    height: 2.4rem
    width: 2.4rem
    border: solid .1rem $beige-700
    background-color: $white
    border-radius: 50%

    &.disabled
      cursor: default
      pointer-events: none
      opacity: 0.5

  .selectButton

    .checkIcon
      opacity: 0

    &.selected
      border: solid .1rem $blue-600
      background-color: $blue-100
      border-radius: 50%

      .checkIcon
        opacity: 1

  .favButton

    .favDisabled
      background-color: $gray-200

    &:hover
      background-color: $beige-600
      transition: background-color .3s ease

    &.favSelected
      background-color: $white
      border-radius: 50%

  .editVisualButton

    &:hover
      background-color: $beige-600
      transition: background-color .3s ease

  .editButton

    &:hover
      background-color: $beige-600
      transition: background-color .3s ease

    &.editSelected
      background-color: $white
      border-radius: 50%

  .downloadButton

    &:hover
      background-color: $beige-600
      transition: background-color .3s ease
