.phoneInput
  \:global(.iti)
    input
      width: 100%

    &:global(.iti--allow-dropdown input),
    &:global(.iti--separate-dial-code input)
      padding-left: 5.5rem

    \:global(.iti__selected-flag)
      padding: 0 .75rem 0 1.5rem
