.pintura-editor
  --color-background: 33, 34, 35
  --color-foreground: 255, 255, 255
  --color-foreground-5: rgba(255, 255, 255, 0.2)

.PinturaRootWrapper
  /* Styles to rewrite nav tabs width */
  .PinturaRoot[data-env~=landscape] > .PinturaNavMain button
    width: 8em

  /* Styles to move pintura preview little to right when nav is wider */
  .PinturaRoot[data-env~=landscape][data-env~=has-navigation]
    grid-template-columns: 10em auto
