@import 'styles/redesign-variables'

.icon
  width: 1.6rem
  height: 1.6rem
  color: $gray-800
  padding-right: 0.4rem

  &.person
    width: 2rem
    height: 2rem
    padding-right: 0.2rem

  &.date
    width: 2rem
    height: 2rem
    padding-right: 0.2rem

.owner
  font-size: 1.4rem
  font-weight: 500
  color: $gray-900
  line-height: 1.96rem
